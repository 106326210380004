// import { io } from 'socket.io-client';
// import { mainURL } from '@/api_client'

// let socket = null;

// export const connectSocket = (userId) => {
// 	if (!socket) {
// 		socket = io(mainURL, {
// 			auth: {
// 				userId,
// 			},
// 		});
// 	}
// 	return socket;
// };

// export const getSocket = () => {
// 	if (!socket) {
// 		throw new Error('Socket is not initialized. Call connectSocket() first.');
// 	}
// 	return socket;
// };

// export const disconnectSocket = () => {
// 	if (socket) {
// 		socket.disconnect();
// 		socket = null;
// 	}
// };

let socket = null;
import { io } from 'socket.io-client';
import { mainURL } from '@/api_client'
import { useAuthStore } from '@/store/auth'

export const connectSocket = (notificationHandler) => {
	const authStore = useAuthStore()
	const userId = authStore.user.id;
	if (socket) {
		return;
	}

	// let url = mainURL+'api/'
	// console.log(url)

	socket = io(mainURL, {
		auth: {
			userId,
		},
	});

	socket.on('connect', () => {
		console.log('Connected WebSocket');
	});

	socket.on('notification', (message) => {
		notificationHandler(message)
	});

	socket.on('disconnect', () => {
		console.log('Disconnected WebSocket');
	});
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
		socket = null;
		console.log('Disconnected WebSocket');
	}
};